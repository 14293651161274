import React from "react";
import * as Icon from "react-feather";

const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Layout size={20} />,
    navLink: "/",
  },
  //! -----------Home Page---------
  {
    id: "homePage",
    title: "Home",
    type: "item",
    icon: <Icon.Home size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/home-page",
  },

  //!---------------- Add Property--------
  {
    id: "addPropertyList",
    title: "Properties",
    type: "item",
    icon: <Icon.Package size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/property/list",
  },

  //!---------------- Add Building--------
  {
    id: "addbuildingList",
    title: "Buildings",
    type: "item",
    icon: <Icon.Package size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/building/list",
  },

  //!---------------- Service--------
  {
    id: "servicelist",
    title: "Services",
    type: "item",
    icon: <Icon.Circle size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/service/list",
  },
  //!---------------- News--------
  {
    id: "newsList",
    title: "News",
    type: "item",
    icon: <Icon.Circle size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/news/list",
  },
  //!---------------- career --------
  {
    id: "careerList",
    title: "Career",
    type: "item",
    icon: <Icon.Circle size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/career/list",
  },
  //!---------------- More About --------
  {
    id: "moreAboutList",
    title: "More About",
    type: "item",
    icon: <Icon.Circle size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/moreAbout/list",
  },
  //!---------------- History Slider --------
  {
    id: "historyList",
    title: "History of Quality Investment",
    type: "item",
    icon: <Icon.Circle size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/history/list",
  },
  //!---------------- Properties by Emirates Slider --------
  {
    id: "emiratesList",
    title: "Properties by Emirates",
    type: "item",
    icon: <Icon.Circle size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/emirates/list",
  },
  //!---------------- Properties by Emirates Slider --------
  {
    id: "emailEnquiriesList",
    title: "Email Enqueries",
    type: "item",
    icon: <Icon.Mail size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/enquiries/list",
  },
  //!---------------- Teams Slider --------
  {
    id: "teamsList",
    title: "Property Leasing Team",
    type: "item",
    icon: <Icon.Circle size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/team/list",
  },
  //!---------------- Finance Teams Slider --------
  {
    id: "fteamsList",
    title: "Finance Team",
    type: "item",
    icon: <Icon.Circle size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/finance/list",
  },
  //!---------------- Project ManagementTeams Slider --------
  {
    id: "pteamsList",
    title: "Project Management Team",
    type: "item",
    icon: <Icon.Circle size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/project-management/list",
  },

  //!--------------Pages-------
  {
    id: "pages",
    title: "Pages",
    type: "item",
    icon: <Icon.Paperclip size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/pages",
  },
  {
    id: "staticPages",
    title: "Static Pages",
    type: "collapse",
    icon: <Icon.Anchor size={15} />,
    children: [
      {
        id: "about-us",
        title: "About Us",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/about-us",
      },
      {
        id: "residential",
        title: "Residential",
        type: "item",
        icon: <Icon.Clipboard size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/residential",
      },
      {
        id: "commercial",
        title: "Commercial",
        type: "item",
        icon: <Icon.Clipboard size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/commercial",
      },
      {
        id: "industrial",
        title: "Industrial",
        type: "item",
        icon: <Icon.Clipboard size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/industrial",
      },
      {
        id: "service",
        title: "Service",
        type: "item",
        icon: <Icon.PlayCircle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/service",
      },
      {
        id: "contact",
        title: "Contact",
        type: "item",
        icon: <Icon.Phone size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/contact-us",
      },
      {
        id: "careers",
        title: "Career",
        type: "item",
        icon: <Icon.PlayCircle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/careers",
      },
      {
        id: "agent",
        title: "Real Estate Agent",
        type: "item",
        icon: <Icon.PlayCircle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/registration",
      },
      {
        id: "news-page",
        title: "News",
        type: "item",
        icon: <Icon.PlayCircle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/news",
      },
      {
        id: "privacyPolicy",
        title: "Privacy Policy",
        type: "item",
        icon: <Icon.Clipboard size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/privacy-policy",
      },
      {
        id: "termsfUse",
        title: "Terms Of Service",
        type: "item",
        icon: <Icon.Clipboard size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/terms-of-service",
      },
      {
        id: "testimonials",
        title: "Testimonials",
        type: "item",
        icon: <Icon.Clipboard size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/testimonials",
      },
    ],
  },
  //!------------Static Pages--------
  {
    id: "questionAndAnswersForm",
    title: "FAQ",
    type: "item",
    icon: <Icon.HelpCircle size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/faq",
  },

  //!---------------Users------
  // {
  //   id: "users",
  //   title: "Registered Users",
  //   type: "item",
  //   icon: <Icon.User size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/users-list",
  // },

  //!---------------Reviews------
  {
    id: "reviews",
    title: "Reviews",
    type: "item",
    icon: <Icon.Star size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/reviews/list",
  },
  //!---------------Header------
  // {
  //   id: "header",
  //   title: "Header",
  //   type: "item",
  //   icon: <Icon.Flag size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/header",
  // },
  //!---------------Footer------
  {
    id: "footer",
    title: "Footer",
    type: "item",
    icon: <Icon.CheckSquare size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/footer",
  },

  //!---------------FormsDropDown------
  {
    id: "FormsDropDown",
    title: "Filter Queries",
    type: "item",
    icon: <Icon.ShoppingBag size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/filter-list",
  },
  {
    id: "Investment",
    title: "Investment",
    type: "item",
    icon: <Icon.User size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/investment-list",
  },
  {
    id: "Agents",
    title: "Agent Requests",
    type: "item",
    icon: <Icon.User size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/agents-list",
  },

  //!-------------Gallery--------
  {
    id: "gallery",
    title: "Gallery",
    type: "item",
    icon: <Icon.Image size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/gallery",
  },
];

export default navigationConfig;
